import React, { useEffect,useState } from "react";
import { EditOutlined, CopyOutlined, DeleteOutlined, PhoneOutlined, } from '@ant-design/icons';
import { DateFormat } from '../Components/Constant';
import { Table, Typography, Tooltip, Popconfirm, Checkbox, Avatar, Tag } from 'antd';
import { getUniqueColor } from '../Components/Utility';

import dayjs from 'dayjs';

const { Title } = Typography;

const ListViewTable = (
    fieldsData,
    records,
    fetchRecords,
    handleLabelClick,
    handleCloneClick,
    handleEditClick,
    deleteRecord,
    totalRecords,
    pageSize,
    setPageSize,
    selectedListView,
    currentPage
) => {


    const [columns, setColumns] = useState([]);
    useEffect(() => {

        console.log('before setting columns fields and records are');
        console.log(fieldsData);
        console.log(fieldsData.fieldsData);
        console.log(fieldsData.records);
       

        fetchColumns();
        
    }, [fieldsData, records]);

    const fetchColumns = () => {

        console.log('hello i am going to set columns with fields and records');
        console.log(fieldsData,fieldsData);
        console.log(fieldsData.records);

        // Filter fields, but always include the auto-number field
        const filteredFieldsData = (fieldsData.fieldsData).filter(
            field => !['recordCount', 'CreatedBy', 'LastModifiedBy'].includes(field.name)
        );

        // Separate the "Name" and "Auto-number" fields
        const nameField = filteredFieldsData.find(field => field.name === 'Name');
        // Get other fields, excluding "Name" and "Auto-number" fields
        const otherFields = filteredFieldsData
            .filter(field => field.name !== 'Name' && !field.is_auto_number)

        // Combine columns in the desired sequence: Name, Auto-number, other fields
        const fieldsToShow = [nameField, ...otherFields].filter(Boolean); // filter(Boolean) removes undefined

        const columns = fieldsToShow.map((field, index) => ({
            title: field.label,
            dataIndex: field.name,
            key: field.name,
            sorter: field.name === 'Name' ? (a, b) => (a[field.name]?.localeCompare(b[field.name]) || 0) : undefined,
            sorter: field.type === 'Integer' ? (a, b) => a.Num - b.Num : undefined,
            fixed: field.name === 'Name' ? 'left' : undefined,

            render: (text, record) => {
                if (field.type === 'boolean') {
                    return <Checkbox checked={text} disabled />;
                } else if (field.type === 'Date') {
                    return text ? dayjs(text).format(DateFormat) : ''; // Format date as DD-MM-YYYY
                } else if (field.type === 'DateTime') {
                    return text ? dayjs(text).utc().format('DD/MM/YYYY HH:mm:ss') : ''; // Format DateTime as DD/MM/YYYY HH:mm:ss
                }
                else if (field.type === 'currency') {
                    return text ? `$${text.toFixed(2)}` : ''; // Format as currency with dollar sign
                } else if (field.type === 'Integer') {
                    return text === undefined || text === null ? '' : text === 0 ? '0' : text; // Show 0 for blank or zero values
                } else if (field.type === 'percentage') {
                    return text ? `${text * 100}%` : ''; // Format as currency with dollar sign
                }
                else if (field.type === 'decimal') {
                    return text === undefined || text === null || text === '0' ? '' : Number(text).toFixed(2); // Show 0.00 for blank values
                } else if (field.type === 'Email') {
                    return text ? (
                        <a href={`mailto:${text}`} target="_blank" rel="noopener noreferrer">
                            {text}
                        </a>
                    ) : '';
                } else if (field.type === 'URL') {
                    return text ? (
                        <a href={text.startsWith('http') ? text : `http://${text}`} target="_blank" rel="noopener noreferrer">
                            {text}
                        </a>
                    ) : '';
                } else if (field.type === 'lookup') {

                    let lookupId = '';

                    lookupId = record[field.name];
                    const ob = field.parent_object_name;

                    if (!record[field.name + '_id']) {
                        return '';
                    }
                    else {
                        return (
                            <a
                                href={`/record/${ob}/${lookupId?._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                            >
                                <Avatar
                                    size='small'
                                    style={{
                                        backgroundColor: '#87d068',
                                        marginRight: 8,
                                    }}
                                >
                                    {lookupId?.Name.charAt(0).toUpperCase()}
                                </Avatar>
                                {lookupId?.Name}
                            </a>

                        );
                    }
                }
                else if (field.type === 'Address') {
                    // Ensure the address is properly constructed from the record
                    const address = record[field.name]; // Access the address object
                    if (address) {
                        // Combine address fields into a single string
                        const { street, city, state, country, postal_code } = address;
                        return [
                            street,
                            city,
                            state,
                            country,
                            postal_code,
                        ].filter(Boolean).join(', '); // Join non-empty fields with a comma
                    }
                    return ''; // Return an empty string if the address is not available
                } else if (field.type === 'Phone' && text) {
                    // Add PhoneOutlined for phone numbers
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PhoneOutlined style={{ marginRight: 8 }} />
                            {text || ''}
                        </div>
                    );
                }
                else if (field.type === 'Rich-Text') {
                    console.log('Text-Area filed')
                    return (
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    );
                }
                else if (field.type === 'MultiSelect') {
                    // Check if text is an array (i.e., the MultiSelect field has multiple values)
                    return Array.isArray(text) ? (
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                            {text.map((value, index) => (
                                <Tag
                                    key={index}
                                    color={getUniqueColor(index)} // Get a unique color for each tag
                                    style={{
                                        fontSize: '14px', // Adjust font size for better readability
                                        padding: '6px 12px', // Adjust padding for larger size
                                        borderRadius: '8px', // Rounded corners for aesthetic appeal
                                        lineHeight: '1.5', // Adjust line height for better spacing
                                    }}
                                >
                                    {value}
                                </Tag>
                            ))}
                        </div>
                    ) : (
                        text || ''
                    );
                }

                return index === 0 ? (
                    <a onClick={() => fieldsData.handleLabelClick(record)}>{text}</a>
                ) : (
                    text || ''
                );
            },
        }));

        columns.push({
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => (
                <>
                    <Tooltip title="Edit">
                        <EditOutlined
                            onClick={() => fieldsData.handleEditClick(record)}
                            style={{ marginRight: 8, fontSize: '14px', cursor: 'pointer' }}
                        />
                    </Tooltip>

                    <Tooltip title="Clone">
                        <CopyOutlined
                            onClick={() => fieldsData.handleCloneClick(record)}
                            style={{ marginRight: 8, fontSize: '14px', cursor: 'pointer' }}
                        />
                    </Tooltip>

                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure you want to delete this item?"
                            onConfirm={() => fieldsData.deleteRecord(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined style={{ color: 'red', marginRight: 8, fontSize: '14px', cursor: 'pointer' }} />
                        </Popconfirm>
                    </Tooltip>
                </>
            ),

            
        });

        setColumns(columns);
    }

    return (

        <>
       
            <Table
                columns={columns}
                dataSource={fieldsData.records}
                scroll={{
                    x: 'calc(1000px + 50%)',
                    y: 500
                }}
                pagination={{
                    current: fieldsData.currentPage,
                    pageSize:fieldsData.pageSize, // Use the dynamic pageSize from state
                    total: fieldsData.totalRecords, // Total records for pagination
                    showSizeChanger: true, // Enable page size options
                    pageSizeOptions: ["10", "20", "50", "100"], // Options for page sizes
                    onChange: (page, size) => {
                        fieldsData.fetchRecords(fieldsData.selectedListView, page, size); // Fetch records for the selected page
                    },
                    onShowSizeChange: (current, size) => {
                        fieldsData.setPageSize(size); // Update pageSize state when the user selects a new option
                        fieldsData.fetchRecords(fieldsData.selectedListView, current, size); // Fetch records with updated pageSize
                    },
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`, // Display range and total

                }}
            />

            
        </>

    );

}

export default ListViewTable;