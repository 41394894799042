import React, { useState, useEffect } from 'react';
import { Table, Typography, Tooltip, Button, Row, Col, Menu, message, Spin, Modal, Card, Breadcrumb, Popconfirm, List,Upload,Divider } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined,UploadOutlined,FileOutlined,DownloadOutlined } from '@ant-design/icons';
import CreateObjectDrawer from './CreateObjectDrawer';
import { BASE_URL } from '../Components/Constant';
import ApiService from '../Components/apiService';

const { Title } = Typography;
const {Text} = Typography;
const DataTable = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dependencyModalVisible, setDependencyModalVisible] = useState(false);
  const [dependencyMessage, setDependencyMessage] = useState('');
  const [dependencies, setDependencies] = useState([]);
  const navigate = useNavigate();
  const [objectFile, setObjectFile] = useState(null);
  const [fieldsFile, setFieldsFile] = useState(null);

 
  const openModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setObjectFile(null);
    setFieldsFile(null);
    setIsModalVisible(false);
    
  };
  const handleSave = async () => {
    // Add save logic here
    console.log("Save button clicked");
    if (!objectFile || !fieldsFile) {
      message.error('Both Object and Fields files are required!');
      return;
    }
    console.log('handel saveeeeeee');
    console.log(objectFile);
    console.log(fieldsFile);

    const formData = new FormData();
    formData.append('object_file', objectFile.file);
    formData.append('field_file', fieldsFile.file);
    console.log(formData);
    console.log(formData);
    try {
      // Configure the API call
     
      const uploadService = new ApiService(`${BASE_URL}/insert_metadata`, {
        'Content-Type': "application/x-www-form-urlencoded"
      }, 'POST', formData);
      const response = await uploadService.makeCall();
  
      // Check the response
      if(!response || response.error){
        message.error('Failed to upload files.');
        setIsModalVisible(true);
      }
      else if (response.ok) {
        message.success('Files uploaded successfully!');
        setIsModalVisible(false);
        setObjectFile(null);
        setFieldsFile(null);
      } 
    } catch (error) {
      message.error(error.message || 'An error occurred during the upload.');
    }

    // setIsModalVisible(false);
  };
  const fetchData = async () => {
    setLoading(true);
    try {

      const apiService = new ApiService(`${BASE_URL}/mt_objects`, {
        'Content-Type': 'application/json'
      }, 'GET'
      );

      const response = await apiService.makeCall();

      // Set the data state with the response
      setData(response.map((item) => ({
        ...item, // Keeps all fields of the item
        key: item._id,
      })));
    } catch (error) {
      console.error('Error fetching object list:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === '1') {
      // Handle edit
    } else if (e.key === '2') {
      // deleteRecord(selectedRecord);
    }
  };

  const handleEdit = (record) => {
    console.log('editing recorrrrrdddd');
    console.log(record);
    setEditingRecord(record);
    setDrawerVisible(true);
    setTimeout(() => {
      const drawerContent = document.querySelector('.ant-drawer-body');
      if (drawerContent) {
        drawerContent.scrollTop = 0; // Reset scroll to the top
      }
    }, 200);
  };

  const showDrawer = () => {
    setEditingRecord(null);
    setDrawerVisible(true);
    setTimeout(() => {
      const drawerContent = document.querySelector('.ant-drawer-body');
      if (drawerContent) {
        drawerContent.scrollTop = 0; // Reset scroll to the top
      }
      // Reset the page scroll position
    window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    setEditingRecord(null);
    fetchData();
  };

  const handleAddOrEditObject = (updatedObject) => {
    setData((prevData) =>
      prevData.map((item) => (item.key === updatedObject.key ? updatedObject : item))
    );
  };

  const handleLabelClick = (record) => {
    if (record.key) {
      navigate(`/object-setup/${record.key}`, { state: { record } });
    } else {
      console.error("Record ID is undefined");
    }
  };

  const handleDelete = async (record) => {
    setLoading(true);
    try {
      const apiServiceForObject = new ApiService(
        `${BASE_URL}/delete_object`,
        { 'Content-Type': 'application/json' },
        'POST',
        JSON.stringify({ object_name: record.name })
      );
      const deletionResponse = await apiServiceForObject.makeCall();
      setData((prevData) => prevData.filter((item) => item.key !== record.key));
      message.success('Record deleted successfully.');
    } catch (error) {
      if (error.dependent_objects && error.dependent_objects.length > 0) {
        const dependencies = error.dependent_objects.map(dep => ({
          model: dep.related_model,
          field: dep.field,
          count: dep.count
        }));
        setDependencies(dependencies);
        setDependencyMessage(error.message);
        setDependencyModalVisible(true);
      } else {
        message.error(error.error);
        console.error('Error deleting record:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDependencyModalOk = () => {
    setDependencyModalVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Edit</Menu.Item>
      {/* <Menu.Item key="2" onClick={() => deleteRecord(selectedRecord)}>Delete</Menu.Item> */}
    </Menu>
  );

  const columns = [
    {
      title: 'Label',
      width: 100,
      dataIndex: 'label',
      key: 'label',
      fixed: 'left',
      render: (text, record) => (
        <a onClick={() => handleLabelClick(record)}>{text}</a>
      ),
    },
    {
      title: 'API Name',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Plural Label',
      dataIndex: 'pluralLabel',
      key: 'pluralLabel',
      width: 100,
    },


    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <>
          <Tooltip title="Edit">
            <EditOutlined
              onClick={() =>
                handleEdit(record)}
              style={{ marginRight: 8, fontSize: '18px', cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', marginRight: 8, fontSize: '14px', cursor: 'pointer' }} />
          </Popconfirm>
        </Tooltip>
        </>
      ),
    },


  ];

const handleFileUploadObject = async (file) => {
    console.log(file.file);
    setObjectFile(file); // Save the uploaded file for Object
    message.success('Object file selected successfully.');
    console.log(objectFile);
  };

  const handleFileUploadFields = async (file) => {
    console.log(file.file);
    setFieldsFile(file); // Save the uploaded file for Object
    message.success('Fields file selected successfully.');
    console.log(fieldsFile);
  };
  
  const handleDeleteObjectFile = () => {
    setObjectFile(null);
    message.info('Object file removed.');
  };

  const handleDeleteFieldsFile = () => {
    setFieldsFile(null);
    message.info('Fields file removed.');
  };

  return (
    <Spin spinning={loading}>
      <Card>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
            <Col>
              <Title level={3} style={{ margin: 0 }}>Object Setup</Title>
            </Col>
            <Col>
              <Button type="primary" onClick={showDrawer}>Create +</Button>
              <Button type="primary" onClick={openModal} style={{margin:"5px" }}>Import Object & Field</Button>
              <Modal
        title="Import Options"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        // bodyStyle={{ paddingTop: "5px" }} 
      >
        <Divider style={{marginTop:"10px"}}/>
        {/* Content inside Modal */}
        <Row gutter={[16, 16]} justify="center" align="top">
          {/* Row for Object */}
          <Col span={24}>
            <Row gutter={90} align="middle">
          <Col>
            <Upload
              customRequest={handleFileUploadObject} // Use custom file upload handler
              showUploadList={false} // Hide default upload list
            >
              <Button icon={<UploadOutlined />}>Upload Object</Button>
            </Upload>
            </Col>
              <Col>
                <Button
                  type="link"
                  href="/Sample File/Sample_CSV_Object_Import.csv" // Link to your sample file
                  download="Sample_CSV_Object_Import.csv"
                  icon={<DownloadOutlined />}
                >
                  Download Sample Object File
                </Button>
              </Col>
            </Row>
            {objectFile && (
              <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <FileOutlined style={{ marginRight: '8px' }} />
                <Text
                  style={{
                    maxWidth: '150px', // Adjust width as needed
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  title={objectFile.file.name} // Tooltip to show full name on hover
                >
                  {objectFile.file.name}
                </Text>
                <DeleteOutlined
                style={{ color: 'red', marginLeft: '8px', cursor: 'pointer' }}
                onClick={handleDeleteObjectFile}
              />
              </div>
            )}
          </Col>
          {/* Row for Fields */}
          <Col span={24}>
            <Row gutter={95} align="middle">
          <Col>
            <Upload
              customRequest={handleFileUploadFields} // Use custom file upload handler
              showUploadList={false} // Hide default upload list
            >
              <Button icon={<UploadOutlined />}>Upload Fields</Button>
            </Upload>
            </Col>
              <Col>
                <Button
                  type="link"
                  href="/Sample File/Sample_CSV_Fields_Import.csv" // Link to your sample file
                  download="Sample_CSV_Fields_Import.csv"
                  icon={<DownloadOutlined />}
                >
                  Download Sample Fields File
                </Button>
              </Col>
            </Row>
            {fieldsFile && (
              <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <FileOutlined style={{ marginRight: '8px' }} />
                <Text
                  style={{
                    maxWidth: '150px', // Adjust width as needed
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  title={fieldsFile.file.name} // Tooltip to show full name on hover
                >
                  {fieldsFile.file.name}
                </Text>
                <DeleteOutlined
                style={{ color: 'red', marginLeft: '8px', cursor: 'pointer' }}
                onClick={handleDeleteFieldsFile}
              />
              </div>
            )}
          </Col>
        </Row>

        {/* Modal Footer */}
        <Row justify="end" style={{ marginTop: '20px' }}>
          <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Row>
      </Modal>
            </Col>
          </Row>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={true}
              style={{ width: '100%' }}
            />
          </div>
          <CreateObjectDrawer
            visible={drawerVisible}
            onClose={onCloseDrawer}
            onAddOrEditObject={handleAddOrEditObject}
            editingRecord={editingRecord}
          />
        </div>
      </Card>
      <Modal
        title="Cannot Delete Object"
        open={dependencyModalVisible}
        onOk={handleDependencyModalOk}
        onCancel={handleDependencyModalOk}
        centered
      >
        <p>{dependencyMessage}</p>
        <List
          dataSource={dependencies}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={`Model: ${item.model}`}
                description={`Field: ${item.field}, Count: ${item.count}`}
              />
            </List.Item>
          )}
        />
      </Modal>
    </Spin>
  );
};
 
export default DataTable;


