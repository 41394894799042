import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Table, Typography, Button, Tooltip, Popconfirm, Row, Col, Drawer, Form, Input, Checkbox, Card, Dropdown, Menu, message, Select, DatePicker, Spin, Modal, Space, Upload, Avatar, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, EditOutlined, CopyOutlined,SearchOutlined,CloseOutlined, DeleteOutlined, ImportOutlined, SettingOutlined, CaretDownOutlined, PhoneOutlined, FilterOutlined, ExportOutlined } from '@ant-design/icons';
import { BASE_URL, DateFormat } from '../Components/Constant';
import dayjs from 'dayjs';
import CreateRecordDrawer from './CreateRecordDrawer';
import CreateListViewDrawer from '../Object/CreateListViewDrawer';
import ApiService from '../Components/apiService'; // Import ApiService class
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { generateBody, formatRecordData, fetchLookupData, colors, getUniqueColor } from '../Components/Utility';
import ExportModal from '../ImportExport/ExportModal';
import ListViewTable from './ListViewTable';

dayjs.extend(customParseFormat);

const { Title } = Typography;
const { Option } = Select;


const ObjectSetupDetail = () => {

  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [objectPluralName, setobjectPluralName] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [fieldsData, setFieldsData] = useState([]);
  const [fieldsDataDrawer, setFieldsDataDrawer] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [lookupName, setLookupName] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [listViews, setListViews] = useState([]);
  const [selectedView, setSelectedView] = useState('');
  const [selectedListView, setSelectedListView] = useState(null); // State to store the selected list view for editing
  const [isListViewDrawerVisible, setIsListViewDrawerVisible] = useState(false);
  const [objectForListView, setObjectForListView] = useState();
  const [ListViewInDrawer, SetListViewInDrawer] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0); // Total number of records
  const [isMetadataFetched, setIsMetadataFetched] = useState(false); // Track metadata fetching
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const [searchRecord, setSearchRecord] = useState('');
  const [showInput, setShowInput] = useState(false); // State to toggle input visibility
  const [allRecords,setAllRecords]=useState([]);
  const [pageSize,setPageSize] = useState(10);
  const [isData,setIsData]=useState(false);
  const [isCreateNewRecord,setIsCreateNewRecord] = useState(false);


  useEffect(() => {
    setIsMetadataFetched(false);
    setError('');
    setRecords([]);
    setTotalRecords('');
    // setLoading(true);

   

    const fetchmetadata = async () => {
      try {
        const apiServiceForObject = new ApiService(
          `${BASE_URL}/mt_objects/${id}`,
          { 'Content-Type': 'application/json' },
          'GET'
        );
        const objectResponse = await apiServiceForObject.makeCall();

        setObjectForListView(objectResponse);
        setObjectName(objectResponse?.name);
        setobjectPluralName(objectResponse?.pluralLabel);

        const apiServiceForFields = new ApiService(
          `${BASE_URL}/mt_fields/object/${objectResponse?.name}`,
          { 'Content-Type': 'application/json' },
          'GET'
        );

        const getFieldsResponse = await apiServiceForFields.makeCall();
        setFieldsDataDrawer(getFieldsResponse);

        // Mark metadata as fetched
        setIsMetadataFetched(true);
        // setLoading(false);
      } catch (error) {
        console.error('Error fetching metadata:', error);
        setLoading(false);

      }
    };

    fetchmetadata();
    // setSelectedView('recentlyViewed');
    // fetchRecords('recentlyViewed');
  }, [id]);

  useEffect(() => {
    setRecords([]);
    if (isMetadataFetched) {
      setCurrentPage(1); // Reset to the first page
      setSelectedListView(null); // Clear selected view
      setSelectedView('recentlyViewed'); // Reset selected view state
      fetchRecords('recentlyViewed', 1); // Fetch records with updated metadata
    }
  }, [id, isMetadataFetched]); // Trigger only when metadata is fetched

  const fetchRecords = async (selectedViewId, page = currentPage, size = pageSize) => {
    setLoading(true);

    const offsetValue = (page - 1) * size; 
    const limit = size;    
    let apiServiceForRecords;

    try {
      if(selectedViewId == 'recentlyViewed'){
        console.log('Fetching recently viewed records');
          apiServiceForRecords = new ApiService(
              `${BASE_URL}/recent_view/${objectName}`,
              { 'Content-Type': 'application/json' },
              'GET'
          );
      }
      else if (selectedViewId) {
        apiServiceForRecords = new ApiService(
          `${BASE_URL}/mt_list_views/${selectedViewId?._id}/records/${limit}/${offsetValue}`,
          { 'Content-Type': 'application/json' },
          'GET'
        );

      } else {
        apiServiceForRecords = new ApiService(
          `${BASE_URL}/fetch_records/${objectName}/${limit}/${offsetValue}`,
          { 'Content-Type': 'application/json' },
          'GET'
        );
      }

      const recordsResponse = await apiServiceForRecords.makeCall();
     
      if (recordsResponse.records) {
        setRecords(recordsResponse.records);
        setAllRecords(recordsResponse.records);
      }
      else {
        setRecords(recordsResponse);
        setAllRecords(recordsResponse);
      }
      setTotalRecords(recordsResponse.total);

      if (selectedViewId?._id || selectedViewId === 'recentlyViewed' ) {
        // Get the field names from the recordsResponse
        const recordFieldNames = Object.keys(recordsResponse[0] || {}); // First record as an example
        const fieldOfListView = selectedViewId?.fields_to_display|| recordFieldNames;

        // Filter fields from fieldsResponse based on whether their name exists in the recordFieldNames
        const matchingFields = fieldsDataDrawer.filter(field => {
          if (field.type === 'lookup') {
            // Check if the field name is 'User', then match with fieldName + '_id'

            // Otherwise, match with fieldName.toLowerCase() + '_id'
            return fieldOfListView.includes(field.name + '_id');
          }
          return fieldOfListView.includes(field.name);
        });
        // Set only the matching fields
        setFieldsData(matchingFields);
      }
      else {
        
        setFieldsData(fieldsDataDrawer);

      }
    }
    catch (err) {
      console.log(err);
      const errorMessage = err && typeof err === 'object'
        ? Object.entries(err).map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`).join(' | ')
        : 'Failed to save record due to an unknown error';
      setError(err.response?.data?.error || errorMessage);
    }
    finally {
      setLoading(false);
    }
    setCurrentPage(page);
    setPageSize(size);
  }

  useEffect(() => {
    setCurrentPage(1);
    if (objectName) {
      fetchListViews();
    }
  }, [objectName]);


  const fetchListViews = async () => {
    const apiService = new ApiService(
      `${BASE_URL}/list-views/${objectName}`,
      { 'Content-Type': 'application/json' },
      'GET'
    );
    try {
      const response = await apiService.makeCall();
      setListViews(response.list_views); // Update state with fetched data
      console.log(listViews);

    } catch (error) {
      console.error("Error fetching list views:", error); // Log any errors
    } finally {
      // setLoading(false); // Set loading to false after the API call
    }
  };

  const handleViewChange = (value) => {
    console.log('id of view is ');
    console.log(value);
    if (value === 'recentlyViewed') {
      console.log('Recently Viewed selected');
      setSelectedListView(null); // Clear the selected list view
      setSelectedView(value);
      fetchRecords('recentlyViewed'); // Fetch recently viewed records
      return;
    }
    else if (value === "All Records") {
      setSelectedListView(null); // Clear the selected list view
      setSelectedView("All Records"); // Update the selected view to "All Records"
      setPageSize(10); // Reset page size to default
      setCurrentPage(1); // Reset to the first page
      fetchRecords(null, 1, 10); // Fetch all records with default pagination
      return;
    }
    console.log(listViews);
    const matchedView = listViews.find(view => view._id === value);
    console.log('list view');
    console.log(matchedView);

    setSelectedListView(matchedView);

    setSelectedView(value);
    console.log(value);
    if (matchedView) {
      console.log('Fetching records for selected view...');
      setPageSize(10); // Reset page size to default for the new view
      setCurrentPage(1); // Reset to the first page
      fetchRecords(matchedView, 1, 10); // Fetch records for the selected view with default pagination
    } else {
      console.log('Fetching all records...');
      setPageSize(10); // Reset page size to default
      setCurrentPage(1); // Reset to the first page
      fetchRecords(null, 1, 10); // Fetch all records with default pagination
    }
  
  };


  const handleCreateClick = async () => {
    setSelectedRecord(null); // Ensure no record is selected when creating a new record
    form.resetFields(); // Clear the form fields
    setDrawerVisible(true);
    setIsCreateNewRecord(true);
    setTimeout(() => {
      const drawerContent = document.querySelector('.ant-drawer-body');
      if (drawerContent) {
        drawerContent.scrollTop = 0; // Reset scroll to the top
      }
    }, 200);

    try {
      //setLoading(true);

      // Create an instance of ApiService for fetching fields data
      const apiServiceForFields = new ApiService(
        `${BASE_URL}/mt_fields/object/${objectName}`,
        { 'Content-Type': 'application/json' },
        'GET' // Specify the method as 'GET'
      );

      // Fetch the fields data
      const response = await apiServiceForFields.makeCall();
      setFieldsDataDrawer(response);

    } catch (error) {
      console.error('Error fetching API response:', error);
    } finally {
      // setLoading(false); // Stop the spinner regardless of success or failure
    }
  };


  const handleEditClick = async (record) => {
    // Fetch fields data first to check for date fields
    setIsCreateNewRecord(false);
    try {
      //setLoading(true);

      const apiServiceforCurrentRecord = new ApiService(
        `${BASE_URL}/fetch_single_record/${objectName}/${record._id}`,
        { 'Content-Type': 'application/json' },
        'GET' // Specify the method as 'GET'
      );
      // Create an instance of ApiService for fetching fields data
      const apiServiceForFields = new ApiService(
        `${BASE_URL}/mt_fields/object/${objectName}`,
        { 'Content-Type': 'application/json' },
        'GET' // Specify the method as 'GET'
      );

      const recordResponse = await apiServiceforCurrentRecord.makeCall();
      const fieldsResponse = await apiServiceForFields.makeCall();
      setFieldsDataDrawer(fieldsResponse);

      // Format date fields in the record before setting them in the form
      const formattedRecord = await formatRecordData(recordResponse, fieldsResponse, BASE_URL);
      setSelectedRecord(formattedRecord);
      form.setFieldsValue(formattedRecord);
      setDrawerVisible(true);

      setTimeout(() => {
        const drawerContent = document.querySelector('.ant-drawer-body');
        if (drawerContent) {
          drawerContent.scrollTop = 0; // Reset scroll to the top
        }
      }, 300);

      // Fetch lookup data
      fetchLookupData(recordResponse, fieldsResponse, BASE_URL, setLookupName, form);

      console.log('form');
      console.log(form.getFieldValue('Account'));

    } catch (error) {
      console.error('Error fetching API response:', error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(()=>{

    if(fieldsData.length > 0 && records.length > 0){
      setIsData(true);
    }else{
      setIsData(false);

    }

  },[fieldsData,records])


  const handleCloneClick = async (record) => {
    try {
      // Fetch the original record data
      const apiServiceforCurrentRecord = new ApiService(
        `${BASE_URL}/fetch_single_record/${objectName}/${record._id}`,
        { 'Content-Type': 'application/json' },
        'GET'
      );

      const recordResponse = await apiServiceforCurrentRecord.makeCall();

      // Fetch fields data
      const apiServiceForFields = new ApiService(
        `${BASE_URL}/mt_fields/object/${objectName}`,
        { 'Content-Type': 'application/json' },
        'GET'
      );

      const fieldsResponse = await apiServiceForFields.makeCall();
      setFieldsDataDrawer(fieldsResponse);

      // Clone and remove ID and set isClone to true
      const clonedRecord = { ...recordResponse, _id: undefined, isClone: true };

      // Format the cloned record data
      const formattedClonedRecord = await formatRecordData(clonedRecord, fieldsResponse, BASE_URL);
      setSelectedRecord(formattedClonedRecord);
      form.setFieldsValue(formattedClonedRecord);

      // Fetch and set lookup data
      fetchLookupData(clonedRecord, fieldsResponse, BASE_URL, setLookupName, form);

    } catch (error) {
      console.error('Error fetching API response:', error);
    } finally {
      // setLoading(false);
    }

    setDrawerVisible(true); // Open the drawer after setting the values
  };
 


  const handleFinish = async (values) => {
    console.log(values);
    const updatedValues = generateBody(fieldsDataDrawer, values);
    const body = {
      object_name: objectName,
      data: {
        _id: selectedRecord?._id && !selectedRecord?.isClone ? selectedRecord._id : undefined, // If cloning, exclude the ID
        ...updatedValues // Use the updated values
      }
    };
    console.log('body is');
    console.log(body);

    try {
      //setLoading(true);


      // Create an instance of ApiService for the POST request
      const apiService = new ApiService(
        `${BASE_URL}/insert_or_update_records`,
        { 'Content-Type': 'application/json' },
        'POST',
        body
      );

      const response = await apiService.makeCall();

      message.success(selectedRecord?._id && !selectedRecord?.isClone ? 'Record updated successfully' : 'Record created successfully');
      setDrawerVisible(false);
      console.log('list view is fetched');
      console.log(selectedListView);
      //fetchRecords('recentlyViewed',currentPage,pageSize);
      form.resetFields();
      if (response.id && isCreateNewRecord) {
        navigate(`/record/${objectName}/${response.id}`);
      } else if(!isCreateNewRecord){
        fetchRecords('recentlyViewed',currentPage);
      }
    } catch (error) {
      const errorMessage = error && typeof error === 'object'
        ? Object.entries(error).map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`).join(' | ')
        : 'Failed to save record due to an unknown error';
      message.error(errorMessage);
    } finally {
      // setLoading(false); // Ensure loading is stopped regardless of success or failure
    }
  };

  const handleFinishFailed = (errorInfo) => {
    const { errorFields } = errorInfo;
    errorFields.forEach((field) => {
      const fieldName = field.name.join('.'); // Handle nested field names
      message.error(`${fieldName} is required.`);
    });
  };


  const handleLabelClick = (record) => {
    console.log(id);
    if (record._id) {
      navigate(`/record/${objectName}/${record._id}`, { state: { record, objectid: id } });
    } else {
      console.error("Record ID is undefined");
    }
  };



  const deleteRecord = async (record) => {
    try {
      // Create ApiService instance for DELETE request
      const apiService = new ApiService(
        `${BASE_URL}/delete_record/${objectName}/${record._id}`,
        {}, // Headers (if any)
        'DELETE'
      );

      await apiService.makeCall();
      message.success('Record deleted successfully.');
      fetchRecords(selectedListView, currentPage);
    } catch (error) {
      message.error('Failed to delete record.');
      console.error('Error deleting record:', error);
    }
  };


  const confirmDelete = async () => {
    deleteRecord(selectedRecord);

    setIsDeleteModalVisible(false);

  };

  if (error) {
    return <p>Error: {error}</p>;
  }


  

  const showCreateListDrawer = (listView) => {
    SetListViewInDrawer(listView); // Set the selected list view for editing
    setIsListViewDrawerVisible(true); // Show the drawer
  };

  const closeCreateListDrawer = () => {
    setIsListViewDrawerVisible(false); // Hide the drawer
  };

  const handleListViewMenuClick = ({ key }) => {
    if (key === 'create') {
      showCreateListDrawer();
    } else if (key === 'edit') {
      showCreateListDrawer(selectedListView);
    } else if (key === 'clone') {
      if (selectedListView) {
        // Clone the selected list view without the id and modify the name
        const clonedListView = {
          ...selectedListView, // clone the selected list view
          _id: undefined, // remove the id
          list_view_name: `Cloned by ${selectedListView?.list_view_name}`, // replace name with the cloned name
        };
        console.log(clonedListView);

        // Show the drawer with the cloned list view
        showCreateListDrawer(clonedListView);
      }
    }
    else if (key === 'delete') {
      console.log('delete clicked');
    }
  };

  const listViewMenu = (
    <Menu onClick={handleListViewMenuClick}>
      <Menu.Item key="create">Create</Menu.Item>
      <Menu.Item key="edit" disabled={!selectedListView}>Edit</Menu.Item>
      <Menu.Item key="clone" disabled={!selectedListView}>Clone</Menu.Item>
      <Menu.Item key="delete" disabled={!selectedListView}>Delete</Menu.Item>

    </Menu>
  );

  const handleFileUpload = () => {
    navigate(`/import`);
  };

  const mapOperator = (operator) => {
    switch (operator) {
      case "$gt":
        return ">";
      case "$gte":
        return ">=";
      case "$lt":
        return "<";
      case "$lte":
        return "<=";
      case "$ne":
        return "!=";
      default:
        return operator; // Fallback for unknown operators
    }
  };

  const handleSearch = () => {
    const filteredRecords = allRecords.filter((record) => {
      // Combine all relevant fields into a searchable string
      const searchableValues = [
        ...Object.values(record).map((value) =>
          typeof value === "object" && value !== null
            ? Object.values(value).join(" ") // Include nested object values
            : value.toString()
        ),
      ].join(" ").toLowerCase();
  
      return searchableValues.includes(searchRecord.toLowerCase());
    });  
    setRecords(filteredRecords);
  };  
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchChange = (e) => {

    const value = e.target.value;
    setSearchRecord(value);
    // handleSearch();


    // Reset records if the input is cleared
    if (value.trim() === "") {
      setRecords(allRecords); // Replace with your original records
    }
  };


  return (
    <Card>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
            <Col >
              <Title level={3} style={{ marginTop: '10px' }}>Records for {objectPluralName}</Title>
              <Select value={selectedView} onChange={handleViewChange} style={{ width: 200, marginBottom: 16 }}>
                <Option value="">All Records</Option>
                <Option value="recentlyViewed">Recently Viewed</Option>
                {listViews.map(view => (
                  <Option key={view._id} value={view._id}>{view.list_view_name}</Option>
                ))}
              </Select>

              <Dropdown overlay={listViewMenu} trigger={['click']}>
                <Button
                  type="text"
                  style={{
                    marginLeft: 10, marginBottom: 16, border: '1px solid #d9d9d9',
                  }}
                >
                  <SettingOutlined />
                  <CaretDownOutlined style={{ marginLeft: 4 }} />
                </Button>
              </Dropdown>

              {!showInput ? (
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => setShowInput(true)}
                    type="variant"
                    style={{
                      marginLeft: 10, marginBottom: 16, border: '1px solid #d9d9d9',
                    }}
                    
                  />
                ) : (
                  <Input
                    placeholder="Search in the list"
                    style={{ width: "200px", marginLeft: "5px"}}
                    value={searchRecord}
                    onChange={handleSearchChange}                  
                    onKeyDown={handleKeyPress}
                    onBlur={() => setShowInput(false)} // Optional: Hide input on blur
                    autoFocus // Automatically focus when it appears
                    prefix={<SearchOutlined />} // Left search icon
                    suffix={
                      <CloseOutlined
                      style={{
                        cursor: "pointer",
                      }}
                        onClick={() => {
                          setSearchRecord(""); // Clear the input
                          handleSearchChange('')
                          setShowInput(false); // Hide the input
                        }}
                      />
                    }                 
                    />
                )}

              {selectedListView?.conditions && (
                <div style={{ display: 'inline-flex', alignItems: 'center', marginLeft: 10 }}>
                  <FilterOutlined style={{ fontSize: '14px', marginRight: 5 }} />
                  {Object.entries(selectedListView?.conditions).map(([key, condition]) => (
                    <Tag key={key} color="blue" style={{ marginRight: 5 }}>
                      {/* Display field and value appropriately */}
                      {condition.field} {typeof condition.value === 'object'
                        ? Object.entries(condition.value).map(([operator, value]) => `${mapOperator(operator)} ${value}`).join(', ')
                        : `= ${condition.value.toString()}`}
                    </Tag>
                  ))}
                </div>
              )}
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <Button icon={<ExportOutlined />} onClick={() => setIsExportModalVisible(true) } style={{ marginBottom: 5, marginRight: '5px' }}>
                Export Records
              </Button>
              <Button icon={<ImportOutlined />} onClick={handleFileUpload} style={{ marginBottom: 5, marginRight: '5px' }}>
                Import Records
              </Button>
              <Button type="primary" onClick={handleCreateClick} style={{ marginBottom: 5, marginRight: '5px' }}>
                Create Record
              </Button>

            </Col>
          </Row>
          <div style={{ flex: 1, overflow: 'auto' }}>
          <Spin spinning={loading} tip="Loading..." size="small">
          
          {isData && 
          
          <ListViewTable
            fieldsData={fieldsData}
            records={records}
            handleLabelClick={handleLabelClick}
            handleCloneClick={handleCloneClick}
            handleEditClick={handleEditClick}
            deleteRecord={deleteRecord}
            fetchRecords={fetchRecords}
            setPageSize={setPageSize}
            selectedListView={selectedListView}
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={totalRecords}
            >

          </ListViewTable>
          }
            </Spin>
          </div>


           {(drawerVisible &&      
          <CreateRecordDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            onFinish={handleFinish}
            loading={loading}
            fieldsData={fieldsDataDrawer}
            selectedRecord={selectedRecord}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            form={form}
            handleFinishFailed={handleFinishFailed}
          />
        )}
 
          {(isListViewDrawerVisible &&     
          <CreateListViewDrawer
            visible={isListViewDrawerVisible}
            onClose={closeCreateListDrawer}
            object={objectForListView}
            fetchListViews={fetchListViews}
            selectedListView={ListViewInDrawer} // Pass selected list view for editing
           
          />
        ) }

          <Modal
            title="Confirm Deletion"
            visible={isDeleteModalVisible}
            onOk={confirmDelete}
            onCancel={() => setIsDeleteModalVisible(false)}
            okText="Delete"
            cancelText="Cancel"
            centered
          >
            <p>Are you sure you want to delete this record?</p>
          </Modal>
          <ExportModal isExportModalVisible={isExportModalVisible} setIsExportModalVisible={setIsExportModalVisible} objectPluralName={objectPluralName} objectName={objectName} record={allRecords[0]} />
        </div>
    </Card>
 

  );
};

export default ObjectSetupDetail;
