import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Typography, Button, Card, message } from 'antd';
import CreateApprovalProcessDrawer from './CreateApprovalProcessDrawer';
import ApiService from '../Components/apiService';
import { BASE_URL } from '../Components/Constant';

const { Title } = Typography;

const ApprovalProcess = ({ object }) => {
  // State to manage the visibility of the drawer
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [approvalProcesses, setApprovalProcesses] = useState([]);
  

  console.log('OBJECT :');
  console.log(object);

  // Trigger the fetch function when the component mounts or object changes
  useEffect(() => {
      console.log('useEffect triggered with object.name:', object.name);
      fetchApprovalProcesses();
    
  }, );

  // Function to fetch approval processes
  const fetchApprovalProcesses = async () => {
    try {
      const apiServiceForLookup = new ApiService(
        `${BASE_URL}/approval_processes/object_name/${object.name}`,
        { 'Content-Type': 'application/json' },
        'GET'
      );
      const response = await apiServiceForLookup.makeCall();
  
      console.log('Response:', response);
console.log('Response Data:', response[0]);
console.log('Is Array:', Array.isArray(response.data));
  
        if (Array.isArray(response)) {
        const processes = response.map((process) => ({
          key: process._id, // Use _id as a unique key
          name: process.name, // Extract the name field
        }));
        setApprovalProcesses(processes);
        console.log('Mapped Approval Processes:', processes);
      } else {
        message.warning('No approval processes found.');
      }
    } catch (error) {
      // message.error('Failed to fetch approval processes.');
      console.error(error);
    }
  };
  

  // Columns for the table
  const columns = [
    {
      title: 'Approval Process Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
  ];

  // Function to open the drawer
  const openDrawer = () => {
    setDrawerVisible(true);
  };

  // Function to close the drawer
  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Title level={3} style={{ marginTop: '10px' }}>
            Approval Process
          </Title>
        </Col>
        <Col style={{ marginTop: '10px' }}>
          <Button type="primary" style={{ marginBottom: 5 }} onClick={openDrawer}>
            Create
          </Button>
        </Col>
      </Row>

      {/* Ant Design Table */}
      <Card>
        <Table
          columns={columns}
          dataSource={approvalProcesses}
          pagination={{ pageSize: 5 }}
        />
      </Card>

      {/* CreateApprovalProcessDrawer */}
      <CreateApprovalProcessDrawer
        visible={drawerVisible}
        onClose={closeDrawer}
        object={object}
      />
    </div>
  );
};

export default ApprovalProcess;
